
















































































































































































































































































































































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import CClarifyAddModal from '@/modules/budget-clarify/components/ClarifyAddModal.vue';
import CBudgDiscloseRate from '@/modules/budget-clarify/components/BudgetDiscloseClarRate.vue';

interface IClarify {
    abp: any;
    data: IProg[];
}

interface IProg {
    prog: any;
    subData: ISubProg[];
    valData: IValData | null;
}

interface ISubProg {
    subProg: any | null;
    data: IValData;
}

interface IValData {
    approvVal: number | null;
    utochVal: number | null;
    offerVal: number | null;
}


@Component({
    components: {
        'c-add-modal': CClarifyAddModal,
        'c-disclose-rate': CBudgDiscloseRate
    }
})
export default class CBudgClarifyRate extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private curYear!: number;

    @Prop({
        required: true,
        default: ''
    })
    private region!: string;

    @Prop({
        required: true,
        default: null
    })
    private curVariant!: any | null;

    private addShow = false;
    private clarifyData: any[] = []; // массив таблица в интерфейсе
    private clarifyBaseData: any[] | null = null; // данные budget_clarify_rate из базы
    private collapseArr: any[] = [];

    private saveDislose = false;

    private edited = false; // возможность редактирования

    private costMap: any | null = null; // map из таблицы Cost для добавления в clarifyData

    private collapseAll = false;

    private progress = 0;

    // добавить новую запись
    private addClk(obj: any) {
        const collapse = this.collapseAll;
        const newClar = this.setCostToClarify(null, obj);
        let abpFl = false;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.clarifyData.length; i++) {
            const abp: IClarify = this.clarifyData[i];
            if (abp.abp.abp === obj.abp.abp) {
                abpFl = true;
                let progFl = false;
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < abp.data.length; j++) {
                    const prog: IProg = abp.data[j];
                    if (prog.prog.prg === obj.prog.prg) {
                        progFl = true;
                        if (obj.subProg) {
                            // eslint-disable-next-line @typescript-eslint/prefer-for-of
                            for (let s = 0; s < prog.subData.length; s++) {
                                const subProg = prog.subData[s];
                                if (subProg.subProg.ppr === obj.subProg.ppr) {
                                    this.makeToast('warning', 'Ошибка добавления', 'Подпрограмма уже существует!');
                                    return;
                                }
                            }
                            this.clarifyData[i].data[j].subData.push({subProg: obj.subProg, valData: newClar});
                            this.collapseArr[i].data[j].subData.push({disclose: true});
                        }
                        break;
                    }
                }
                if (!progFl) {
                    if (obj.subProg) {
                        this.clarifyData[i].data.push({
                            prog: obj.prog,
                            subData: [{subProg: obj.subProg, valData: newClar}]
                        });
                        this.collapseArr[i].data.push({collapse: collapse, subData: [{disclose: true}]});
                    } else {
                        this.clarifyData[i].data.push({prog: obj.prog, subData: [], valData: newClar});
                        this.collapseArr[i].data.push({collapse: null, disclose: true, subData: []});
                    }
                }
                this.calcAbp(this.clarifyData[i]);
                break;
            }
        }
        if (!abpFl) {
            if (obj.subProg) {
                this.clarifyData.push({
                    abp: obj.abp,
                    data: [{prog: obj.prog, subData: [{subProg: obj.subProg, valData: newClar}]}]
                });
                this.collapseArr.push({collapse: collapse, data: [{collapse: collapse, subData: [{disclose: true}]}]});
            } else {
                this.clarifyData.push({abp: obj.abp, data: [{prog: obj.prog, subData: [], valData: newClar}]});
                this.collapseArr.push({collapse: collapse, data: [{collapse: null}]});
            }
            this.calcAbp(this.clarifyData[this.clarifyData.length - 1]);
        }
    }

    // ---- добавить costMap (утверждённый уточнённый бюджеты в clarifyData)
    private setCostToClarify(clarifyObj: any | null, filter: any) {
        let result: any = {
            id: null,
            optimVal: null,
            economVal: null,
            addNeedVal: null,
            repartPlusVal: null,
            repartMinVal: null,
            note: null,
            total: 0,
            totalUtoch: 0
        };
        if (clarifyObj) {
            result = Object.assign({}, clarifyObj);
        }
        result.approvVal = null;
        result.utochVal = null;
        if (this.costMap) {
            if (this.costMap.get(filter.abp.abp) && this.costMap.get(filter.abp.abp).get(filter.prog.prg)) {
                if (filter.subProg && filter.subProg.ppr) {
                    const subProg = this.costMap.get(filter.abp.abp).get(filter.prog.prg);
                    if (subProg && subProg.subProg.get(filter.subProg.ppr)) {
                        result.approvVal = subProg.subProg.get(filter.subProg.ppr).approvVal;
                        result.utochVal = subProg.subProg.get(filter.subProg.ppr).utochVal;
                        result.offerVal = subProg.subProg.get(filter.subProg.ppr).offerVal;
                    }
                } else {
                    result.approvVal = this.costMap.get(filter.abp.abp).get(filter.prog.prg).val.approvVal;
                    result.utochVal = this.costMap.get(filter.abp.abp).get(filter.prog.prg).val.utochVal;
                    result.offerVal = this.costMap.get(filter.abp.abp).get(filter.prog.prg).val.offerVal;
                }
            }
        }
        return result;
    }

    private clkCollapse(abpIndx: number, progIndx: number | null) {
        if (this.collapseArr[abpIndx].collapse === null) {
            return;
        }
        if (progIndx === null) {
            this.collapseArr[abpIndx].collapse = !this.collapseArr[abpIndx].collapse;
        } else {
            this.collapseArr[abpIndx].data[progIndx].collapse = !this.collapseArr[abpIndx].data[progIndx].collapse;
        }
        this.collapseArr.push({});
        this.collapseArr.splice(this.collapseArr.length - 1, 1);
    }

    private clkDisclose(abpIndx: number, progIndx: number, subIndx: number | null) { // показать/скрыть раскрывающуюся таблицу
        if (subIndx === null) {
            this.collapseArr[abpIndx].data[progIndx].disclose = !this.collapseArr[abpIndx].data[progIndx].disclose;
        } else {
            this.collapseArr[abpIndx].data[progIndx].subData[subIndx].disclose = !this.collapseArr[abpIndx].data[progIndx].subData[subIndx].disclose;
        }
        this.collapseArr.push({});
        this.collapseArr.splice(this.collapseArr.length - 1, 1);
    }

    private chgCollapseAll() {
        for (const abp of this.collapseArr) {
            abp.collapse = this.collapseAll;
            for (const prog of abp.data) {
                if (prog.collapse !== null) {
                    prog.collapse = this.collapseAll;
                }
            }
        }
        this.collapseArr.push(false);
        this.collapseArr.splice(this.collapseArr.length - 1, 1);
    }

    private mounted() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.$watch('collapseAll', that.chgCollapseAll);
    }

    private calcTotal(obj: any) {
        obj.total = 0;
        if (obj.optimVal) {
            obj.total += parseFloat(obj.optimVal);
        }
        if (obj.economVal) {
            obj.total += parseFloat(obj.economVal);
        }
        if (obj.addNeedVal) {
            obj.total += parseFloat(obj.addNeedVal);
        }
        if (obj.repartPlusVal) {
            obj.total += parseFloat(obj.repartPlusVal);
        }
        if (obj.repartMinVal) {
            obj.total += parseFloat(obj.repartMinVal);
        }
        obj.totalUtoch = obj.total;
        if (obj.utochVal) {
            obj.totalUtoch += parseFloat(obj.utochVal);
        }
        obj.total = Math.round(obj.total);
        obj.totalUtoch = Math.round(obj.totalUtoch);
    }

    // --- clarifyBaseData преобразование в clarifyData (дерево) и добавление данных из costMap
    private getClarifyData() {
        this.clarifyData = [];
        this.collapseArr = [];
        if (this.costMap === null || this.clarifyBaseData === null) {
            return;
        }
        this.progress = 75;
        const result: any[] = [];
        // -- абп
        for (const clar of this.clarifyBaseData) {
            const el = this.setCostToClarify(Object.assign({total: 0, totalUtoch: 0}, clar), {
                abp: clar.abp,
                prog: clar.prog,
                subProg: clar.subProg
            });
            this.calcTotal(el);
            let flAbp = false;
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let a = 0; a < result.length; a++) {
                if (result[a].abp.abp === el.abp.abp) {
                    result[a].data.push(el);
                    flAbp = true;
                    break;
                }
            }
            if (!flAbp) {
                result.push({abp: el.abp, data: [el]});
            }
        }
        result.sort((a, b) => (a.abp.abp > b.abp.abp) ? 1 : -1);
        // --
        for (let a = 0; a < result.length; a++) {
            const elAbp = result[a];
            this.collapseArr.push({collapse: this.collapseAll, data: []});
            const resProg: any[] = [];
            for (const progEl of elAbp.data) {
                let flProg = false;
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < resProg.length; i++) {
                    if (resProg[i].prog.prg === progEl.prog.prg) {
                        if (progEl.subProg.ppr !== null) {
                            resProg[i].subData.push({subProg: progEl.subProg, valData: progEl});
                            this.collapseArr[a].data[i].subData.push({disclose: true});
                        } else {
                            resProg[i].valData = progEl;
                        }
                        flProg = true;
                        break;
                    }
                }
                if (!flProg) {
                    if (progEl.subProg.ppr !== null) {
                        resProg.push({prog: progEl.prog, subData: [{subProg: progEl.subProg, valData: progEl}]});
                        this.collapseArr[a].data.push({collapse: this.collapseAll, subData: [{disclose: true}]});
                    } else {
                        resProg.push({prog: progEl.prog, subData: [], valData: progEl});
                        this.collapseArr[a].data.push({collapse: null, disclose: true, subData: []});
                    }
                }
            }
            // eslint-disable-next-line no-shadow
            resProg.sort((a, b) => (a.prog.prg > b.prog.prg) ? 1 : -1);
            elAbp.data = resProg;
        }

        for (const el of result) {
            this.calcAbp(el);
        }
        this.clarifyData = result;
        this.progress = 100;
    }

    // -------- расчёт общую сумму в абп и программах
    private calcAbp(abpObj: any) {
        let totalAbp: any | null = null;
        for (const el of abpObj.data) {
            if (el.subData && el.subData.length) {
                let totalProg: any | null = null;
                for (const sb of el.subData) {
                    totalProg = this.calcRowTotal(totalProg, sb.valData);
                }
                el.totalData = totalProg;
                totalAbp = this.calcRowTotal(totalAbp, el.totalData);
            } else {
                totalAbp = this.calcRowTotal(totalAbp, el.valData);
            }
        }
        abpObj.totalData = totalAbp;
    }

    private calcRowTotal(totalRow: any | null, obj: any) {
        let totalRes = null;
        let approvVal = 0;
        if (obj.approvVal) {
            approvVal = parseFloat(obj.approvVal);
        }
        let utochVal = 0;
        if (obj.utochVal) {
            utochVal = parseFloat(obj.utochVal);
        }
        let offerVal = 0;
        if (obj.offerVal) {
            offerVal = parseFloat(obj.offerVal);
        }
        let total = 0;
        if (obj.total) {
            total = parseFloat(obj.total);
        }
        let optimVal = 0;
        if (obj.optimVal) {
            optimVal = parseFloat(obj.optimVal);
        }
        let economVal = 0;
        if (obj.economVal) {
            economVal = parseFloat(obj.economVal);
        }
        let addNeedVal = 0;
        if (obj.addNeedVal) {
            addNeedVal = parseFloat(obj.addNeedVal);
        }
        let repartMinVal = 0;
        if (obj.repartMinVal) {
            repartMinVal = parseFloat(obj.repartMinVal);
        }
        let repartPlusVal = 0;
        if (obj.repartPlusVal) {
            repartPlusVal = parseFloat(obj.repartPlusVal);
        }
        let totalUtoch = 0;
        if (obj.totalUtoch) {
            totalUtoch = parseFloat(obj.totalUtoch);
        }
        if (!totalRow) {
            totalRes = {
                approvVal: approvVal,
                utochVal: utochVal,
                offerVal: offerVal,
                total: total,
                optimVal: optimVal,
                economVal: economVal,
                addNeedVal: addNeedVal,
                repartMinVal: repartMinVal,
                repartPlusVal: repartPlusVal,
                totalUtoch: totalUtoch
            };
        } else {
            totalRes = {
                approvVal: totalRow.approvVal + approvVal,
                utochVal: totalRow.utochVal + utochVal,
                offerVal: totalRow.offerVal + offerVal,
                total: totalRow.total + total,
                optimVal: totalRow.optimVal + optimVal,
                economVal: totalRow.economVal + economVal,
                addNeedVal: totalRow.addNeedVal + addNeedVal,
                repartMinVal: totalRow.repartMinVal + repartMinVal,
                repartPlusVal: totalRow.repartPlusVal + repartPlusVal,
                totalUtoch: totalRow.totalUtoch + totalUtoch
            };
        }
        return totalRes;
    }

    // ----------------------------

    private async loadClarifyData() {
        this.clarifyBaseData = null;
        if (!this.region || !this.curYear || !this.curVariant) {
            return;
        }
        this.progress = 45;
        const params = {
            region: this.region,
            curYear: parseInt(this.curYear.toString()),
            variant: this.curVariant.variant_uuid
        };
        let result: any = [];
        try {
            result = await fetch('/api-py/get-budget-clarify-rate/', {
                method: 'POST',
                body: JSON.stringify(params)
            })
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'get-budget-clarify-rate', `${result.status} - ${result.statusText}`);
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-clarify-rate', (error as Error).toString());
            return;
        }
        for (const el of result) {
            el.oldData = {
                addNeedVal: el.addNeedVal,
                economVal: el.economVal,
                optimVal: el.optimVal,
                note: el.note,
                repartMinVal: el.repartMinVal,
                repartPlusVal: el.repartPlusVal
            };
        }
        this.clarifyBaseData = result;
        this.getClarifyData();
    }

    private async loadCostData() {
        this.costMap = null;
        if (!this.region || !this.curYear || !this.curVariant) {
            return;
        }
        this.progress = 45;
        const params = {
            region: this.region,
            curYear: parseInt(this.curYear.toString()),
            variant: this.curVariant.variant_uuid
        };
        let result: any = [];
        try {
            result = await fetch('/api-py/get-budget-cost-claryfy-aprov/', {
                method: 'POST',
                body: JSON.stringify(params)
            });
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'get-budget-cost-claryfy-aprov', `${result.status} - ${result.statusText}`);
                this.progress = 100;
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-cost-claryfy-aprov', (error as Error).toString());
            this.progress = 100;
            return;
        }
        const resultMap: Map<string, any> = this.makeMap(result);
        // -------
        this.costMap = resultMap;
        this.getClarifyData();
    }

    private makeMap(arr: any[]): Map<string, any> {
        const abpMap = new Map();
        // -- abp
        for (const el of arr) {
            const abpEl = abpMap.get(el.abp.abp);
            if (abpEl) {
                abpEl.push(el);
                abpMap.set(el.abp.abp, abpEl);
            } else {
                abpMap.set(el.abp.abp, [el]);
            }
        }
        // ------
        for (const [keyAbp, valAbp] of abpMap) {
            // -- prog
            const progMap = new Map();
            for (const el of valAbp) {
                let progEl: any = progMap.get(el.prog.prg);
                if (!progEl) {
                    progEl = {val: null, subProg: []};
                }
                if (el.subProg.ppr) {
                    progEl.subProg.push(el);
                } else {
                    progEl.val = el;
                }
                progMap.set(el.prog.prg, progEl);
            }
            // ---subProg
            for (const [keyProg, valProg] of progMap) {
                const subProg = new Map();
                for (const el of valProg.subProg) {
                    subProg.set(el.subProg.ppr, el);
                }
                progMap.set(keyProg, {val: valProg.val, subProg: subProg});
            }
            // --
            abpMap.set(keyAbp, progMap);
        }
        return abpMap;
    }

    private async save() {
        const saveArr = [];
        this.saveDislose = !this.saveDislose;
        for (const abp of this.clarifyData) {
            for (const prog of abp.data) {
                let tmp = this.getSaveObj(prog.valData, abp.abp.abp, prog.prog.prg, null);
                if (tmp !== null) {
                    saveArr.push(tmp);
                }
                for (const sub of prog.subData) {
                    tmp = this.getSaveObj(sub.valData, abp.abp.abp, prog.prog.prg, sub.subProg.ppr);
                    if (tmp !== null) {
                        saveArr.push(tmp);
                    }
                }
            }
        }

        try {
            const url = '/api-py/save-budget-clarify-rate';
            let response: any = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(saveArr) // body data type must match "Content-Type" header
            });
            response = await response.json();
            this.loadClarifyData();
            let success = true;
            for (const r of response) {
                if (r.result !== 'success') {
                    success = false;
                    this.makeToast('danger', 'Ошибка сохранения', `${r.result}`);
                }
            }
            if (success) {
                this.makeToast('success', 'Сообщение', 'Данные сохранены');
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения', (error as Error).toString());
        }
    }

    // необходимость сохранения
    private getSaveObj(obj: any, abp: number, prg: number, ppr: number | null): any {
        let result: any = null;
        if (!obj) {
            return null;
        }
        let note = null;
        if (obj.note) {
            note = obj.note.trim();
        }
        if (obj.id !== null) {
            if (obj.oldData.optimVal === this.strToFloat(obj.optimVal) && obj.oldData.economVal === this.strToFloat(obj.economVal) && obj.oldData.addNeedVal === this.strToFloat(obj.addNeedVal) && obj.oldData.repartPlusVal === this.strToFloat(obj.repartPlusVal) && obj.oldData.repartMinVal === this.strToFloat(obj.repartMinVal) && obj.oldData.note === note) {
                return null;
            }
        }
        // eslint-disable-next-line @typescript-eslint/camelcase
        result = {
            variant: this.curVariant.variant_uuid,
            cur_year: this.curYear,
            region: this.region,
            abp: abp,
            prg: prg,
            ppr: ppr,
            id: obj.id,
            optimVal: this.strToFloat(obj.optimVal),
            economVal: this.strToFloat(obj.economVal),
            addNeedVal: this.strToFloat(obj.addNeedVal),
            repartPlusVal: this.strToFloat(obj.repartPlusVal),
            repartMinVal: this.strToFloat(obj.repartMinVal),
            note: note
        };
        return result;
    }

    private chgVal(abpIndx: number, progIndx: number, subProg: number | null) {
        let obj: any = null;
        if (subProg !== null) {
            obj = this.clarifyData[abpIndx].data[progIndx].subData[subProg].valData;
        } else {
            obj = this.clarifyData[abpIndx].data[progIndx].valData;
        }
        this.calcTotal(obj);
        this.calcAbp(this.clarifyData[abpIndx]);
    }

    private deleteRow(abpIndx: number, progIndx: number, subProg: number | null) {
        const txt = 'Удалить запись?';
        this.$bvModal.msgBoxConfirm(
            txt,
            {
                title: 'Подтверждение',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.deleteItem(abpIndx, progIndx, subProg);
                }
            })
            .catch(error => {
                this.makeToast('danger', 'Ошибка удаления', error.toString());
            });
    }

    private async deleteItem(abpIndx: number, progIndx: number, subProg: number | null) {
        let id: any = null;
        if (subProg !== null) {
            id = this.clarifyData[abpIndx].data[progIndx].subData[subProg].valData.id;
        } else {
            id = this.clarifyData[abpIndx].data[progIndx].valData.id;
        }
        if (id) {
            let result: any = await fetch('/api-py/del-budget-clarify-rate',
                {
                    method: 'DELETE',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify({id: id})
                });
            if (result.status === 200) {
                result = await result.json();
                if (result.result === 'success') {
                    this.makeToast('success', 'Удаление', 'Запись удалена');
                } else if (result.result === 'error') {
                    this.makeToast('danger', 'Удаление', `Ошибка ${result.data}`);
                    return;
                }
            } else {
                this.makeToast('danger', 'Удаление', `Ошибка ${result.status} ${result.statusText}`);
                return;
            }
        }

        if (subProg !== null) {
            this.clarifyData[abpIndx].data[progIndx].subData.splice(subProg, 1);
            this.collapseArr[abpIndx].data[progIndx].subData.splice(subProg, 1);
        } else {
            this.clarifyData[abpIndx].data[progIndx].valData = null;
        }
        if (this.clarifyData[abpIndx].data[progIndx].subData.length === 0 && !this.clarifyData[abpIndx].data[progIndx].valData) {
            this.clarifyData[abpIndx].data.splice(progIndx, 1);
            this.collapseArr[abpIndx].data.splice(progIndx, 1);
            if (this.clarifyData[abpIndx].data.length === 0) {
                this.clarifyData.splice(abpIndx, 1);
                this.collapseArr.splice(abpIndx, 1);
            }
        }
    }

    private strToFloat(obj: any): number | null {
        if (obj === null) {
            return null;
        }
        return parseFloat(obj);
    }

    private created() {
        if (this.curVariant) {
            this.edited = this.curVariant.attribute;
        }
        this.loadCostData();
        this.loadClarifyData();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.$watch('curYear', this.chgFilter);
        this.$watch('region', this.chgFilter);
        this.$watch('curVariant', function () {
            that.edited = false;
            if (that.curVariant) {
                that.edited = that.curVariant.attribute;
            }
            that.chgFilter();
        });
    }

    private chgFilter() {
        this.clarifyData = [];
        this.clarifyBaseData = null;
        if (!this.region || !this.curYear || !this.curVariant) {
            return;
        }
        this.loadCostData();
        this.loadClarifyData();
    }

    // ввод только цифр
    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    get totalValue(): any {
        const totalData = {
            "approvVal": 0,
            "utochVal": 0,
            "offerVal": 0,
            "total": 0,
            "optimVal": 0,
            "economVal": 0,
            "addNeedVal": 0,
            "repartMinVal": 0,
            "repartPlusVal": 0,
            "totalUtoch": 0
        }
        for (const abp of this.clarifyData) {
            const abpTotal = abp.totalData;
            totalData.approvVal += abpTotal.approvVal;
            totalData.utochVal += abpTotal.utochVal;
            totalData.offerVal += abpTotal.offerVal;
            totalData.total += abpTotal.total;
            totalData.economVal += abpTotal.economVal;
            totalData.optimVal += abpTotal.optimVal;
            totalData.addNeedVal += abpTotal.addNeedVal;
            totalData.repartMinVal += abpTotal.repartMinVal;
            totalData.repartPlusVal += abpTotal.repartPlusVal;
            totalData.totalUtoch += abpTotal.totalUtoch;
        }
        return totalData;
    }
}
