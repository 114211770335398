






























































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class CBudgDiscloseRate extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private curYear!: number;

    @Prop({
        required: true,
        default: ''
    })
    private region!: string;

    @Prop({
        required: true,
        default: null
    })
    private curVariant!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private abp!: number | null;


    @Prop({
        required: true,
        default: null
    })
    private prg!: number | null;


    @Prop({
        required: false,
        default: null
    })
    private ppr!: number | null;

    @Prop({
        required: true,
        default: false
    })
    private saveEvent!: boolean;

    // -------добавить показатель----------
    private addShow = false;
    private indicUnitBase: any[] = [];

    private indicRegionLst: any[] = [];
    private curIndicRegion: any | null = null;

    private projectLst: any[] = [];
    private curProject: any | null = null;

    private indicatorLst: any[] = [];
    private curIndic: any | null = null;
    // -------------------

    private edited = false;

    private tableData: any[] = [];
    private progress = 0;

    /* private get indicUnitLst(): any[] {
        const result: any = [];
        for (const el of this.indicUnitBase) {
            let fl = false;
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < this.tableData.length; i++) {
                const tbEl = this.tableData[i];
                if (tbEl.indicator.id === el.indicator.id && tbEl.unit.code === el.unit.code) {
                    fl = true;
                    break;
                }
            }
            if (!fl) { result.push(el); }
        }
        this.curIndicUnit = null;
        if (result.length > 0) {
            this.curIndicUnit = result[0];
        }
        return result;
    } */

    // ---------- модальное окно добавления --------------------
    private async loadIndicUnitLst() {
        this.indicUnitBase = [];
        this.indicRegionLst = [];
        const params = { region: this.region, curYear: parseInt(this.curYear.toString()), abp: this.abp, prg: this.prg, ppr: this.ppr };
        let result: any = [];
        try {
            result = await fetch('/api-py/get-budget-clarify-indic_unit/' + encodeURI(JSON.stringify(params)));
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'get-budget-clarify-indic_unit', `${result.status} - ${result.statusText}`);
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-clarify-indic_unit', (error as Error).toString());
        }
        result.sort((a: any, b: any) => (a.region.code > b.region.code) ? 1 : -1);

        const newArr: any[] = [];
        for (const el of result) {
            this.setName(el.project, 'id');
            this.setName(el.region, 'code');
            if (newArr.length && newArr[newArr.length - 1].region.code === el.region.code) {
                newArr[newArr.length - 1].data.push(el);
            } else {
                newArr.push({ region: el.region, data: [el] });
                this.indicRegionLst.push(el.region);
            }
        }
        for (const el of newArr) {
            el.data.sort((a: any, b: any) => (a.project.id - b.project.id > 0) ? 1 : -1);
            const newProj: any[] = [];
            for (const elP of el.data) {
                if (newProj.length && newProj[newProj.length - 1].project.id === elP.project.id) {
                    newProj[newProj.length - 1].data.push(elP);
                } else {
                    newProj.push({ project: elP.project, data: [elP] });
                }
            }
            el.data = newProj;
        }
        this.indicUnitBase = newArr;
        if (this.indicRegionLst.length) {
            this.curIndicRegion = this.indicRegionLst[0];
        }
    }

    private chgIndicRegion() {
        this.projectLst = [];
        this.curProject = null;
        if (this.curIndicRegion === null) { return; }
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.indicUnitBase.length; i++) {
            if (this.indicUnitBase[i].region.code === this.curIndicRegion.code) {
                for (const el of this.indicUnitBase[i].data) {
                    this.projectLst.push(el.project);
                }
                if (this.projectLst.length > 0) { this.curProject = this.projectLst[0]; }
                break;
            }
        }
    }

    private chgProject() {
        this.curIndic = null;
        this.indicatorLst = [];
        if (this.curProject === null) { return; }
        for (const el of this.indicUnitBase) {
            if (el.region.code === this.curIndicRegion.code) {
                for (const elR of el.data) {
                    if (elR.project.id === this.curProject.id) {
                        for (const elP of elR.data) {
                            this.indicatorLst.push({ indicName: `${elP.indicator.name} - ${elP.unit.name}`, indicator: elP.indicator, unit: elP.unit, performIndicVal: elP.performIndicVal, bpDriId: elP.bpDriId });
                        }
                        if (this.indicatorLst.length) { this.curIndic = this.indicatorLst[0]; }
                        return;
                    }
                }
            }
        }
    }

    // -----------------------------------------------

    private setName(obj: any, codeField: string) {
        if (obj[codeField] === null) {
            obj.name = '- - - -';
        } else {
            obj.name = `${obj[codeField]} - ${obj.name}`;
        }
    }

    private async loadData() {
        this.edited = false;
        this.progress = 45;
        if (!this.region || !this.curYear || !this.curVariant) { return; }
        this.edited = this.curVariant.attribute;
        const params = { region: this.region, variant: this.curVariant.variant_uuid, curYear: parseInt(this.curYear.toString()), abp: this.abp, prg: this.prg, ppr: this.ppr };
        let result: any = [];
        try {
            result = await fetch('/api-py/get-budget-clarify-rate-bp/' + encodeURI(JSON.stringify(params)));
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'get-budget-clarify-rate-bp', `${result.status} - ${result.statusText}`);
                this.progress = 100;
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-clarify-rate-bp', (error as Error).toString());
            this.progress = 100;
            return;
        }
        for (const el of result) {
            this.setName(el.project, 'id');
            this.setName(el.region, 'code');
            el.oldData = { indicVal: el.indicVal };
        }
        this.tableData = result;
        this.progress = 100;
    }

    private async save() {
        const saveArr = [];
        for (const el of this.tableData) {
            if (this.editedRow(el)) {
                const obj: any = { id: el.id, variant: this.curVariant.variant_uuid, bpDriId: el.bpDriId, indicVal: null };
                if (el.performIndicVal) { obj.performIndicVal = parseFloat(el.performIndicVal); }
                if (el.indicVal) { obj.indicVal = parseFloat(el.indicVal); }
                saveArr.push(obj);
            }
        }
        if (saveArr.length === 0) { return; }

        try {
            const url = 'api-py/save-budget-clarify-rate-bp';
            let response: any = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(saveArr) // body data type must match "Content-Type" header
            });
            response = await response.json();
            this.loadData();
            for (const r of response) {
                if (r.result !== 'success') {
                    this.makeToast('danger', 'Ошибка сохранения bp', `${r.result}`);
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения bp', (error as Error).toString());
        }
    }

    private editedRow(obj: any): boolean {
        if (obj.id === null) { return true; }
        if (obj.oldData.indicVal !== obj.indicVal) {
            return true;
        }
        return false;
    }

    private deleteRow(indx: number) {
        const txt = 'Удалить запись?';
        this.$bvModal.msgBoxConfirm(
            txt,
            {
                title: 'Подтверждение',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) { this.deleteItem(indx); }
            })
            .catch(error => {
                this.makeToast('danger', 'Ошибка удаления', error.toString());
            });
    }

    private async deleteItem(indx: number | null) {
        const idArr = [];
        if (indx !== null) {
            idArr.push({ id: this.tableData[indx].id });
        } else {
            for (const el of this.tableData) {
                idArr.push({ id: el.id });
            }
        }
        if (idArr.length) {
            let result: any = await fetch('/api-py/del-budget-clarify-rate-bp',
                {
                    method: 'DELETE',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(idArr)
                });
            if (result.status === 200) {
                result = await result.json();
                if (result.result === 'success') {
                    this.makeToast('success', 'Удаление', 'Запись удалена');
                } else if (result.result === 'error') {
                    this.makeToast('danger', 'Удаление', `Ошибка ${result.data}`);
                    return;
                }
            } else {
                this.makeToast('danger', 'Удаление', `Ошибка ${result.status} ${result.statusText}`);
                return;
            }
        }
        if (indx !== null) {
            this.tableData.splice(indx, 1);
        } else {
            this.tableData = [];
        }
    }

    private addRow() {
        const obj = { id: null, bpDriId: this.curIndic.bpDriId, region: this.curIndicRegion, project: this.curProject, indicator: this.curIndic.indicator, unit: this.curIndic.unit, performIndicVal: this.curIndic.performIndicVal, indicVal: 0 };
        for (const el of this.tableData) {
            let fl = false;
            if (el.region === null || obj.region === null) {
                if (el.region === obj.region) { fl = true; }
            } else if (el.region.code === obj.region.code) {
                fl = true;
            }
            if (fl) {
                fl = false;
                if (el.project === null || obj.project === null) {
                    if (el.project === obj.project) { fl = true; }
                } else if (el.project.id === obj.project.id) {
                    fl = true;
                }
                if (fl) {
                    if (el.indicator.id === obj.indicator.id && el.unit.code === obj.unit.code) {
                        this.makeToast('warning', 'Ошибка добавления', 'Показатель уже существует!');
                        return;
                    }
                }
            }
        }
        this.tableData.push(obj);
    }

    private mounted() {
        this.loadIndicUnitLst();
        this.$watch('saveEvent', this.save);
        this.$watch('curIndicRegion', this.chgIndicRegion);
        this.$watch('curProject', this.chgProject);
        this.loadData();
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    // ввод только цифр
    // eslint-disable-next-line consistent-return
    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }
}
