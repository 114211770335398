


































































































import { Component, Vue } from 'vue-property-decorator';
import CBudgClarifyRate from '@/modules/budget-clarify/components/BudgetClarifyRate.vue';
import CBudgClarifyIncome from '@/modules/budget-clarify/components/BudgetClarifyIncome.vue';
import store from '@/services/store';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';

@Component({
    components: {
        'c-clarif-rate': CBudgClarifyRate,
        'c-clarify-income': CBudgClarifyIncome
    }
})
export default class CBudgClarify extends Vue {
    private curYear = (new Date()).getFullYear();
    private tabIndx = 0;

    private variantBase: any[] = [];
    private curVariant: any | null = null;

    private periodLst: any[] = [];
    private curPeriod: any | null = null;
    private maxYear = 3000;
    private minYear = 2000;

    private getPeriodLst() {
        const year = (new Date()).getFullYear();
        this.periodLst = [];
        const baseYear = 2020;
        for (let i = this.curYear-baseYear + 1; i >=1; i--) {
            this.periodLst.push({ name: `${baseYear + i} - ${baseYear + i + 2}`, year: baseYear + i });
            if (year === (baseYear + i)) {
                this.curPeriod = { name: `${baseYear + i} - ${baseYear + i + 2}`, year: baseYear + i };
                this.curYear = year;
                this.maxYear = year + 2;
                this.minYear = year;
            }
        }
    }

    private calculateClarify() {
        console.debug(this.tabIndx);
    }

    private get variantLst() {
        const res: any[] = [];
        for (const el of this.variantBase) {
            res.push(this.setNameLang(el));
        }
        if (this.curVariant !== null) { this.curVariant = this.setNameLang(this.curVariant); }
        return res;
    }

    // -----------------region
    private region: any | null = null;
    private regionBase: any[] = [];

    private get regionLst() {
        const res: any[] = [];
        for (const el of this.regionBase) {
            res.push(this.setNameLang(el, 'code'));
        }
        if (this.region !== null) { this.region = this.setNameLang(this.region, 'code'); }
        return res;
    }

    private async getObl() {
        let obl = '45';
        let result: any = [];
        try {
            result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
            if (result.status === 200) {
                const json = await result.json();
                obl = json.obl;
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }

        try {
            result = await fetch('/api-py/get-user-regions-by-obl/' + obl + '/' + this.$store.getters.user_uuid);
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
                result = [];
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
            result = [];
        }
        this.regionBase = result;
        if (this.regionBase.length > 0) { this.region = this.regionBase[0]; }
    }
    // ---------------------------

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    // ------------- Варианты -------------
    private async loadVariants() {
        this.variantBase = [];
        this.curVariant = null;
        const param = { dataType: 3, region: this.region.code, curYear: parseInt(this.curYear.toString()) };
        try {
            const result = await fetch('/api-py/get-budget-variants-lst/' + encodeURI(JSON.stringify(param)));
            if (result.status === 200) {
                const json = await result.json();
                for (const el of json) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_ru = variantNameLangSupport(el, 'ru');
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_kk = variantNameLangSupport(el, 'kk');
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_en = variantNameLangSupport(el, 'en');
                }
                this.variantBase = json;
                if (this.variantBase.length > 0) {
                    this.curVariant = this.variantBase[0];
                } else {
                    this.makeToast('warning', 'Версия бюджета', 'Нет доступных версий бюджета');
                }
            } else {
                this.makeToast('danger', 'get-budget-variants-lst', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-variants-lst', (error as Error).toString());
        }
    }
    // -----------------------------------

    private async mounted() {
        await this.getObl();
        this.loadVariants();
        this.getPeriodLst();
        this.$watch('curYear', this.loadVariants);
        this.$watch('curPeriod', this.chgCurPeriod);
    }

    private chgCurPeriod() {
        if (this.curPeriod) {
            this.curYear = this.curPeriod.year;
            this.maxYear = this.curPeriod.year + 2;
            this.minYear = this.curPeriod.year;
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (!txt) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private save() {
        if (this.tabIndx === 0) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.budgClarifRate.save();
        } else if (this.tabIndx === 1) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.budgClarifIncome.save();
        }
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }
}
