



































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CClarifyIncomeModal from '@/modules/budget-clarify/components/ClarifyIncomeAddModal.vue';

interface IDohDict {
    kat: number;
    cls: number;
    pcl: number;
    spf: number;
    name: string;
}

interface IIncomeData {
    kat: IDohDict;
    totalData?: IIncomeVal | null;
    collapse: boolean;
    data: IIncomeCls[];
}

interface IIncomeCls {
    cls: IDohDict;
    collapse: boolean;
    totalData?: IIncomeVal | null;
    data: IIncomePcl[];
}

interface IIncomePcl {
    pcl: IDohDict;
    totalData?: IIncomeVal | null;
    collapse: boolean;
    data: IIncomeSpf[];
}

interface IIncomeSpf {
    spf: IDohDict;
    data: IIncome;
}

interface IIncome extends IIncomeVal {
    id: number | null;
    note: string | null;
}

interface IIncomeVal {
    utverVal: number | null;
    correctVal: number | null;
    offerVal: number | null;
    approvVal: number | null;
    utochVal: number | null;
}

@Component({
    name: 'c-clarify-income',
    components: {
        'c-income-modal': CClarifyIncomeModal
    }
})
export default class CBudgClarifyIncome extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private curYear!: number;

    @Prop({
        required: true,
        default: ''
    })
    private region!: string;

    @Prop({
        required: true,
        default: null
    })
    private curVariant!: any | null;

    private progress = 0;

    private addShow = false;

    private collapseAll = false;

    private incomeData: IIncomeData[] = [];
    private incomeDataBase: any [] | null = null;
    private incomMap: Map<number, any> | null = null;

    private edited = false;

    // добавить новую запись
    private addClk(filter: any) {
        let flKat = false;
        let utverVal = null;
        let correctVal = null;
        if (this.incomMap && this.incomMap.get(filter.curKat.kat) && this.incomMap.get(filter.curKat.kat).get(filter.curCls.cls) && this.incomMap.get(filter.curKat.kat).get(filter.curCls.cls).get(filter.curPcl.pcl) && this.incomMap.get(filter.curKat.kat).get(filter.curCls.cls).get(filter.curPcl.pcl).get(filter.curSpf.spf)) {
            utverVal = this.incomMap.get(filter.curKat.kat).get(filter.curCls.cls).get(filter.curPcl.pcl).get(filter.curSpf.spf).utverVal;
            correctVal = this.incomMap.get(filter.curKat.kat).get(filter.curCls.cls).get(filter.curPcl.pcl).get(filter.curSpf.spf).correctVal;
        }


        const addObj: IIncome = { id: null, utverVal: utverVal, correctVal: correctVal, offerVal: null, approvVal: null, utochVal: null, note: null };
        this.calcTotal(null, null, null, null, addObj);
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.incomeData.length; i++) {
            const katObj: IIncomeData = this.incomeData[i];
            if (filter.curKat.kat === katObj.kat.kat) {
                let flCls = false;
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < katObj.data.length; j++) {
                    const clsObj: IIncomeCls = katObj.data[j];
                    if (filter.curCls.cls === clsObj.cls.cls) {
                        let flPcl = false;
                        // eslint-disable-next-line @typescript-eslint/prefer-for-of
                        for (let k = 0; k < clsObj.data.length; k++) {
                            const pclObj: IIncomePcl = clsObj.data[k];
                            if (filter.curPcl.pcl === pclObj.pcl.pcl) {
                                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                                for (let s = 0; s < pclObj.data.length; s++) {
                                    const spfObj: IIncomeSpf = pclObj.data[s];
                                    if (filter.curSpf.spf === spfObj.spf.spf) {
                                        this.makeToast('warning', 'Ошибка добавления', 'Специфика уже существует!');
                                        return;
                                    }
                                }
                                const newObj: IIncomeSpf = { spf: filter.curSpf, data: addObj };
                                pclObj.data.push(newObj);
                                flPcl = true;
                                break;
                            }
                        }
                        if (!flPcl) {
                            const newObj: IIncomePcl = {
                                pcl: filter.curPcl,
                                collapse: this.collapseAll,
                                data: [
                                    { spf: filter.curSpf, data: addObj }
                                ]
                            };
                            clsObj.data.push(newObj);
                        }
                        flCls = true;
                        break;
                    }
                }
                if (!flCls) {
                    const newOjb: IIncomeCls = { cls: filter.curCls,
                        collapse: this.collapseAll,
                        data: [ {
                            pcl: filter.curPcl,
                            collapse: this.collapseAll,
                            data: [
                                { spf: filter.curSpf, data: addObj }
                            ]
                        }]
                    };
                    katObj.data.push(newOjb);
                }
                this.calcKatTotal(this.incomeData[i]);
                flKat = true;
                break;
            }
        }
        if (!flKat) {
            const newObj: IIncomeData = {
                kat: filter.curKat,
                collapse: this.collapseAll,
                data: [
                    { cls: filter.curCls,
                        collapse: this.collapseAll,
                        data: [ {
                            pcl: filter.curPcl,
                            collapse: this.collapseAll,
                            data: [
                                { spf: filter.curSpf, data: addObj }
                            ]
                        }]
                    }
                ]
            };
            this.incomeData.push(newObj);
            this.calcKatTotal(this.incomeData[this.incomeData.length - 1]);
        }
    }

    private async loadIncomeData() {
        this.incomMap = null;
        if (!this.region || !this.curYear) { return; }
        this.progress = 45;
        const params = { region: this.region, curYear: parseInt(this.curYear.toString()) };
        let result: any = [];
        try {
            result = await fetch('/api-py/get-budget-income-claryfy-aprov/' + encodeURI(JSON.stringify(params)));
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'get-budget-income-claryfy-aprov', `${result.status} - ${result.statusText}`);
                this.progress = 100;
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-income-claryfy-aprov', (error as Error).toString());
            this.progress = 100;
            return;
        }
        const resMap = this.getMap(result, 'kat');
        for (const [keyKat, valKat] of resMap) {
            const mapCls = this.getMap(valKat, 'cls');
            const resCls = new Map();
            for (const [keyCls, valCls] of mapCls) {
                const mapPcl = this.getMap(valCls, 'pcl');
                const resPcl = new Map();
                for (const [keyPcl, valPcl] of mapPcl) {
                    resPcl.set(keyPcl, this.getMap(valPcl, 'spf', true));
                }
                resCls.set(keyCls, resPcl);
            }
            resMap.set(keyKat, resCls);
        }
        this.incomMap = resMap;
        this.getIncomeData();
    }

    private getMap(arr: any[], fieldName: string, isObj?: boolean) {
        const resMap = new Map();
        for (const el of arr) {
            let elMap = null;
            if (isObj) {
                elMap = el;
            } else {
                elMap = resMap.get(el[fieldName][fieldName]);

                if (elMap) {
                    elMap.push(el);
                } else {
                    elMap = [el];
                }
            }
            resMap.set(el[fieldName][fieldName], elMap);
        }
        return resMap;
    }


    private async loadData() {
        this.incomeData = [];
        this.incomeDataBase = null;
        if (!this.region || !this.curYear || !this.curVariant) { return; }
        this.progress = 45;
        const params = { region: this.region, curYear: parseInt(this.curYear.toString()), variant: this.curVariant.variant_uuid };
        let result: any = [];
        try {
            result = await fetch('/api-py/get-budget-clarify-income/' + encodeURI(JSON.stringify(params)));
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'get-budget-clarify-income', `${result.status} - ${result.statusText}`);
                result = [];
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-clarify-income', (error as Error).toString());
            result = [];
        }
        for (const el of result) {
            el.oldData = { offerVal: el.offerVal, approvVal: el.approvVal, note: el.note };
        }
        this.incomeDataBase = result;
        this.getIncomeData();
    }

    private getIncomeData() {
        if (this.incomeDataBase === null || this.incomMap === null) { return; }
        const arrRes: any[] = this.getArr(this.incomeDataBase, 'kat');
        for (const el of arrRes) {
            el.data = this.getArr(el.data, 'cls');
            for (const clsEl of el.data) {
                clsEl.data = this.getArr(clsEl.data, 'pcl');
                for (const spfEl of clsEl.data) {
                    const spfRes: any = this.getArr(spfEl.data, 'spf', true);
                    for (const r of spfRes) {
                        let incMapEl;
                        if (this.incomMap.has(r.data.kat.kat)) {
                            if (this.incomMap.get(r.data.kat.kat).has(r.data.cls.cls)) {
                                if (this.incomMap.get(r.data.kat.kat).get(r.data.cls.cls).has(r.data.pcl.pcl)) {
                                    if (this.incomMap.get(r.data.kat.kat).get(r.data.cls.cls).get(r.data.pcl.pcl).has(r.data.spf.spf)) {
                                        incMapEl = this.incomMap.get(r.data.kat.kat).get(r.data.cls.cls).get(r.data.pcl.pcl).get(r.data.spf.spf);
                                    }
                                }
                            }
                        }
                        if (incMapEl) {
                            r.data.correctVal = incMapEl.correctVal;
                            r.data.utverVal = incMapEl.utverVal;
                        } else {
                            r.data.correctVal = null;
                            r.data.utverVal = null;
                        }
                        this.calcTotal(null, null, null, null, r.data);
                    }
                    spfEl.data = spfRes;
                }
            }
            this.calcKatTotal(el);
        }
        this.incomeData = arrRes;
        console.log('incomeData', this.incomeData);
        this.progress = 100;
    }

    // -------- расчёт общей суммы по категориям cls и pcl
    private calcKatTotal(katObj: IIncomeData) {
        let katTotal: IIncomeVal | null = null;
        for (const katEl of katObj.data) {
            let clsTotal: IIncomeVal | null = null;
            for (const clsEl of katEl.data) {
                let pclTotal: IIncomeVal | null = null;
                for (const pclEl of clsEl.data) {
                    pclTotal = this.calcRowTotal(pclTotal, pclEl.data);
                }
                clsEl.totalData = pclTotal;
                if (clsEl.totalData) { clsTotal = this.calcRowTotal(clsTotal, clsEl.totalData); }
            }
            katEl.totalData = clsTotal;
            if (katEl.totalData) { katTotal = this.calcRowTotal(katTotal, katEl.totalData); }
        }
        katObj.totalData = katTotal;
    }

    private calcRowTotal(total: any | null, obj: IIncomeVal | IIncome): IIncomeVal {
        let resTotal: IIncomeVal | null = null;
        let approvVal = 0;
        if (obj.approvVal) { approvVal = parseFloat(obj.approvVal.toString()); }
        let utverVal = 0;
        if (obj.utverVal) { utverVal = parseFloat(obj.utverVal.toString()); }
        let correctVal = 0;
        if (obj.correctVal) { correctVal = parseFloat(obj.correctVal.toString()); }
        let offerVal = 0;
        if (obj.offerVal) { offerVal = parseFloat(obj.offerVal.toString()); }
        let utochVal = 0;
        if (obj.utochVal) { utochVal = parseFloat(obj.utochVal.toString()); }
        if (total === null) {
            resTotal = { approvVal: approvVal, utverVal: utverVal, correctVal: correctVal, offerVal: offerVal, utochVal: utochVal };
        } else {
            resTotal = { approvVal: approvVal + total.approvVal, utverVal: utverVal + total.utverVal, correctVal: correctVal + total.correctVal, offerVal: offerVal + total.offerVal, utochVal: utochVal + total.utochVal };
        }
        return resTotal;
    }
    // ----------------

    private getArr(arr: any[], fieldName: string, isObject?: boolean): any[] {
        arr.sort((a, b) => (a[fieldName][fieldName] - b[fieldName][fieldName] > 0) ? 1 : -1);
        const result: any [] = [];
        for (const el of arr) {
            if (!isObject) {
                if (result.length > 0 && el[fieldName][fieldName] === result[result.length - 1][fieldName][fieldName]) {
                    result[result.length - 1].data.push(el);
                } else {
                    const newObj: any = { data: [el], collapse: this.collapseAll };
                    newObj[fieldName] = el[fieldName];
                    result.push(newObj);
                }
            } else {
                const newObj: any = { data: el };
                newObj[fieldName] = el[fieldName];
                result.push(newObj);
            }
        }
        return result;
    }

    private chgCollapseAll() {
        const collapse = this.collapseAll;
        for (const katEl of this.incomeData) {
            katEl.collapse = collapse;
            for (const clsEl of katEl.data) {
                clsEl.collapse = collapse;
                for (const pclEl of clsEl.data) {
                    pclEl.collapse = collapse;
                }
            }
        }
    }

    private isSave(obj: any): boolean {
        if (!obj.id) { return true; }
        if (obj.oldData.offerVal !== obj.offerVal) { return true; }
        if (obj.oldData.approvVal !== obj.approvVal) { return true; }
        if (obj.oldData.note !== obj.note) { return true; }
        return false;
    }

    private async save() {
        const saveArr: any[] = [];
        for (const katEl of this.incomeData) {
            for (const clsEl of katEl.data) {
                for (const pclEl of clsEl.data) {
                    for (const spfEl of pclEl.data) {
                        if (this.isSave(spfEl.data)) {
                            const saveObj = { id: spfEl.data.id, variant: this.curVariant.variant_uuid, region: this.region, curYear: this.curYear, kat: katEl.kat.kat, cls: clsEl.cls.cls, pcl: pclEl.pcl.pcl, spf: spfEl.spf.spf, offerVal: this.strToFloat(spfEl.data.offerVal), approvVal: this.strToFloat(spfEl.data.approvVal), note: spfEl.data.note };
                            saveArr.push(saveObj);
                        }
                    }
                }
            }
        }
        if (saveArr.length === 0) { return; }
        try {
            const url = '/api-py/save-budget-clarify-income/';
            let response: any = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveArr)
            });
            response = await response.json();
            let success = true;
            for (const r of response) {
                if (r.result !== 'success') {
                    success = false;
                    this.makeToast('danger', 'Ошибка сохранения', `${r.result}`);
                }
            }
            if (success) { this.makeToast('success', 'Сообщение', 'Данные сохранены'); }
            this.chgFilter();
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения', (error as Error).toString());
        }
    }

    private chgData(katIndx: number, clsIndx: number, pclIndx: number, spfIndx: number, calcFl: boolean) {
        if (calcFl) { this.calcTotal(katIndx, clsIndx, pclIndx, spfIndx); }
        this.calcKatTotal(this.incomeData[katIndx]);
    }

    private calcTotal(katIndx: number | null, clsIndx: number | null, pclIndx: number | null, spfIndx: number | null, obj?: any | null) {
        let resObj: any | null = null;
        if (katIndx !== null && clsIndx !== null && pclIndx !== null && spfIndx !== null) {
            resObj = this.incomeData[katIndx].data[clsIndx].data[pclIndx].data[spfIndx].data;
        } else if (obj) {
            resObj = obj;
        }
        if (resObj) {
            let utverVal = 0;
            let approvVal = 0;
            let correctVal = 0;
            if (resObj.utverVal) { utverVal = parseFloat(resObj.utverVal.toString()); }
            if (resObj.approvVal) { approvVal = parseFloat(resObj.approvVal.toString()); }
            if (resObj.correctVal) { correctVal = parseFloat(resObj.correctVal.toString()); }
            resObj.utochVal = correctVal + approvVal;
            if (!obj) {
                this.incomeData.push({ kat: { name: '', kat: 0, cls: 0, pcl: 0, spf: 0 }, data: [], collapse: true });
                this.incomeData.splice(this.incomeData.length - 1, 1);
            }
        }
    }

    private deleteRow(katIndx: number, clsIndx: number, pclIndx: number, spfIndx: number) {
        const txt = 'Удалить запись?';
        this.$bvModal.msgBoxConfirm(
            txt,
            {
                title: 'Подтверждение',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) { this.deleteItem(katIndx, clsIndx, pclIndx, spfIndx); }
            })
            .catch(error => {
                this.makeToast('danger', 'Ошибка удаления', error.toString());
            });
    }

    private async deleteItem(katIndx: number, clsIndx: number, pclIndx: number, spfIndx: number) {
        const id: any = this.incomeData[katIndx].data[clsIndx].data[pclIndx].data[spfIndx].data.id;
        if (id) {
            let result: any = await fetch('/api-py/del-budget-clarify-income',
                {
                    method: 'DELETE',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify([{ id: id }])
                });
            if (result.status === 200) {
                result = await result.json();
                if (result.result === 'success') {
                    this.makeToast('success', 'Удаление', 'Запись удалена');
                } else if (result.result === 'error') {
                    this.makeToast('danger', 'Удаление', `Ошибка ${result.data}`);
                    return;
                }
            } else {
                this.makeToast('danger', 'Удаление', `Ошибка ${result.status} ${result.statusText}`);
                return;
            }
        }
        this.incomeData[katIndx].data[clsIndx].data[pclIndx].data.splice(spfIndx, 1);
        if (this.incomeData[katIndx].data[clsIndx].data[pclIndx].data.length === 0) {
            this.incomeData[katIndx].data[clsIndx].data.splice(pclIndx, 1);
            if (this.incomeData[katIndx].data[clsIndx].data.length === 0) {
                this.incomeData[katIndx].data.splice(clsIndx, 1);
                if (this.incomeData[katIndx].data.length === 0) {
                    this.incomeData.splice(katIndx, 1);
                }
            }
        }
    }

    private mounted() {
        if (this.curVariant) { this.edited = this.curVariant.attribute; }
        this.chgFilter();
        this.$watch('collapseAll', this.chgCollapseAll);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.$watch('curYear', this.chgFilter);
        this.$watch('region', this.chgFilter);
        this.$watch('curVariant', function () {
            that.edited = false;
            if (that.curVariant) { that.edited = that.curVariant.attribute; }
            that.chgFilter();
        });
    }

    private chgFilter() {
        this.incomeData = [];
        this.incomeDataBase = null;
        if (!this.region || !this.curYear || !this.curVariant) { return; }
        this.loadData();
        this.loadIncomeData();
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    // ввод только цифр
    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }

    private strToFloat(obj: any): number | null {
        if (obj === null) { return null; }
        return parseFloat(obj);
    }

    get totalValue(): any {
        const totalData = {
            "approvVal": 0,
            "utverVal": 0,
            "correctVal": 0,
            "offerVal": 0,
            "utochVal": 0
        }
        console.debug('this.incomeData', this.incomeData);
        for (const abp of this.incomeData) {
            const abpTotal = abp.totalData;
            if (abpTotal != null) {
                if (abpTotal.approvVal!= null) {
                    totalData.approvVal += abpTotal.approvVal;
                }
                if (abpTotal.utverVal!= null) {
                    totalData.utverVal += abpTotal.utverVal;
                }
                if (abpTotal.offerVal!= null) {
                    totalData.offerVal += abpTotal.offerVal;
                }
                if (abpTotal.correctVal!= null) {
                    totalData.correctVal += abpTotal.correctVal;
                }
                if (abpTotal.utochVal!= null) {
                    totalData.utochVal += abpTotal.utochVal;
                }
            }
        }
        return totalData;
    }
}
